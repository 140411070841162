@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;700&display=swap');

/* Global CSS reset */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, body, #root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.container {
  width: 90%;
  max-width: 1200px;
  margin: 0 auto;
}

.App {
  text-align: center;
  width: 100%;
  margin: 0;
  padding: 0;
}

.section-spacer {
  height: 60px; /* Adjust the height as needed */
  background: #fff;
  position: relative;
  z-index: 0;
  margin-top: -10px; /* Pulls the spacer underneath the section */
}


.hero {
  text-align: center;
  padding: 50px 0;
}

.hero-container {
  position: relative;
  background-size: cover;
  background-position: center;
  height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  margin: 0;
  padding: 0;
  box-shadow: 0px 10px 20px -5px #333, 0px -10px 20px -5px #333;
  overflow: visible;
  z-index: 1;
}

.hero-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.hero-content {
  position: relative;
  z-index: 1;
  text-align: center;
}

.hero-title {
  font-size: 4rem !important;
  font-weight: 700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-subtitle {
  font-size: 2rem;
  font-weight: 500;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.hero-text {
  margin-top: 4rem !important;
  max-width: 600px;
  margin-left: auto !important;
  margin-right: auto !important;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  text-align: center;
  font-size: 1.25rem !important;
}

.scroll-indicator {
  position: absolute;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2;
  opacity: 1;
  transition: opacity 0.5s ease-out;
  cursor: pointer;
}

.scroll-indicator.hidden {
  opacity: 0;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}

.about-us-section {
  text-align: center;
  background: linear-gradient(135deg, #dddddd, #ffffff);
  box-shadow: 0px -1px 10px -6px #333, 0px -1px 10px -6px #333;
  position: relative;
  z-index: 1;
  overflow: visible;
}

.about-us-section > * {
  position: relative;
  z-index: 1;
}

.our-programs-section {
  text-align: center;
  background: linear-gradient(135deg, #F0ECEC, #b7b4b2);
  box-shadow: 0px -1px 10px -6px #333, 0px -1px 10px -6px #333;
  position: relative;
  z-index: 1;
  overflow: visible;
}


.get-involved-section {
  text-align: center;
  background: linear-gradient(135deg, #dddddd, #ffffff);
  box-shadow: 0px -1px 10px -6px #333, 0px -1px 10px -6px #333;
  position: relative;
  z-index: 1;
  overflow: visible;
}